
import { Component, Vue } from 'vue-property-decorator';
import { MglMap,  MglMarker, MglGeojsonLayer } from "vue-mapbox";
import mapbox from 'mapbox-gl';
import moment from 'moment';
import { ClassDetail } from '@/store/modules/integrationStore';
import { lineString, featureCollection } from '@turf/helpers';
import { getCoord } from '@turf/invariant';
// @ts-ignore
import center from '@turf/center';

@Component({ components: { MglMap, MglMarker, MglGeojsonLayer } })
class DetalhePercurso extends Vue {
    private mounted() {
        setTimeout(() => {
            this.showMap = true;
        }, 600);
    }

    private showMap: boolean = false;
    private percursoLayer = {
        id: 'percursoStyle',
        type: "line",
        paint: {
            'line-width': 1,
            'line-color': '#FBB736',
        }
    };

    private get aula(): ClassDetail{
        return this.$store.getters['getClassDetail'];
    }

    private get inicio(){
        return moment(this.aula.inicio, 'DD-MM-YYYY HH:mm:ss');
    }

    private get fim(){
        return moment(this.aula.fim, 'DD-MM-YYYY HH:mm:ss');
    }

    private get tempo(){
        const miliseconds = this.fim.valueOf() - this.inicio.valueOf();
        const hours = Math.trunc(miliseconds / (3.6 * 10 ** 6));
        const minutes = Math.trunc(miliseconds % (3.6 * 10 ** 6) / (6 * 10 ** 4 ));
        return hours > 0 ?
            hours + 'h' + minutes :
            minutes + 'm';
    }

    // ========== Percurso =============
    mapLoaded() {
        setTimeout(() => {
            const bounds = this.path.reduce(function(bounds, coord) {
                return bounds.extend(coord);
            }, new mapbox.LngLatBounds(this.path[0], this.path[0]));
    
    
            (this.$refs.mapa as any).actions.fitBounds(bounds, {
                padding: 5
            });
        }, 300);
    }

    private get path(){
        return this.aula.percurso.map(el => el.coordinates);
    }

    private get inicioPercurso(){
        return this.path[0];
    }

    private get fimPercurso(){
        return this.path[this.path.length - 1];
    }
    
    private get center() {
        if(this.path.length) {
            const polygon = lineString(this.path);
            const centroid = getCoord(center(polygon));
            return { lat: centroid[1], lng: centroid[0] };
        }
        else {
            return { lat: -7.122828, lng: -34.855302 };
        }
    }

    private get mapSource(){
        const collection = featureCollection([lineString(this.path)]);
        (collection as any).id = "percurso";

        const source = {
            type: "geojson",
            data: collection
        };

        return source;
    }
}

export default DetalhePercurso;
