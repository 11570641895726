
import { Component, Vue } from 'vue-property-decorator';
import FormChat, { FormStep } from '@/components/p-FormChat.vue';
import { alpha_spaces, email } from 'vee-validate/dist/rules';
import { phoneValidator, passwordValidator, cpfValidator, emojiValidator } from '@/utils/validationFunctions';
import NativeService from '@/services/NativeService';
import CameraOptions from '@/enums/CameraOptionsEnum';

interface CustomDocument extends Document {
    finishRegister?: () => void;
    recoverPassword?: () => void;
}

@Component({ components: { FormChat } })
class CriarContaChat extends Vue {
    private mounted(): void {
        // Mapear métodos
        const customDocument: CustomDocument = document;
        customDocument.finishRegister = this.finishRegister;
        customDocument.recoverPassword = this.recoverPassword;
    }

    public nodeEnv: string = process.env.NODE_ENV;

    private fields = {
        nome: '',
        cpf: '',
        email: '', 
        senha: '', 
        confSenha: '',
        imagem: '',
        codUsuarioPilotar: '',
        estado: '',
        telefone: '',
        whatsApp: ''
    };

    private get userName() {
        return this.fields.nome.split(' ')[0];
    }

    private finishMessages: string[] = ['Tudo certo, $USERNAME! 😀👏', 'Agora preciso que você <b><a onclick="document.finishRegister()">clique aqui</a></b> para verificar nossos termos de uso.'];

    private steps: FormStep[] = [
        // Nome
        {
            messages: ['Seja bem-vindo! Eu sou o Pilotar e vou te ajudar no seu processo de formação de condutores.', 'Para isso, preciso te conhecer melhor. Qual o seu nome completo? 😊'],
            fieldName: 'nome',
            inputType: 'text',
            rule: alpha_spaces.validate,
            errorMsg: 'Digite um nome válido.'
        },
        // Estado
        {
            messages: ['$USERNAME, onde você mora? Selecione aqui em baixo o seu estado. 😊'],
            fieldName: 'estado',
            inputType: 'text',
            options: ['Acre', 'Alagoas', 'Amapá', 'Amazonas', 'Bahia', 'Ceará', 'Distrito Federal', 'Espírito Santo', 'Goiás', 'Maranhão', 'Mato Grosso', 'Mato Grosso do Sul', 'Minas Gerais', 'Pará', 'Paraíba', 'Paraná', 'Pernambuco', 'Piauí', 'Rio de Janeiro', 'Rio Grande do Norte', 'Rio Grande do Sul', 'Rondônia', 'Roraima', 'Santa Catarina', 'São Paulo', 'Sergipe', 'Tocantins'],
            errorMsg: 'Por favor, selecione um estado.'
        },
        // Telefone
        {
            messages: ['Legal! Agora vamos validar seu número de telefone.', 'Qual o número do seu celular?'],
            fieldName: 'telefone',
            inputType: 'tel',
            rule: phoneValidator,
            mask: '(##) #####-####',
            errorMsg: 'Por favor, insira um número de telefone válido',
        },
        // Email
        {
            messages: [
                'Ótimo $USERNAME! Seu telefone já está confirmado em nosso sistema. 👍🏼',
                'Me fala o seu e-mail para adicionarmos aqui também! Vamos guardar o seu contato para nos comunicarmos com você caso seja necessário.'
            ],
            fieldName: 'email',
            inputType: 'email',
            request: (message) => this.$store.dispatch('CheckEmail', message),
            rule: (value) => email.validate(value.toString().replace(/[ ]/g, "")) && emojiValidator(value),
            errorMsg: 'Por favor, digite um e-mail válido.',
        },
        // CPF
        {
            messages: ['Qual o número do seu CPF?'],
            fieldName: 'cpf',
            inputType: 'tel',
            request: message => this.$store.dispatch("CheckCpf", message),
            rule: cpfValidator,
            mask: "###.###.###-##",
            errorMsg: 'Por favor, insira um CPF válido',
        },
        // Senha
        {
            messages: ['Ótimo! Agora crie sua senha. Prometo que não vou olhar! 🙈', 'Ela precisa possuir no mínimo 8 dígitos, ter número e letra.'],
            fieldName: 'senha',
            inputType: 'password',
            rule: value => passwordValidator(value) && emojiValidator(value),
            errorMsg: 'A senha precisa possuir no mínimo 8 dígitos, ter número e letra.',
        },
        // Confirmar senha
        {
            messages: ['Massa! Digite-a novamente para que o sistema possa validá-la.'],
            fieldName: 'confSenha',
            inputType: 'password',
            rule: (value) => value == this.fields.senha,
            errorCallback: () => this.fields.senha = '',
            errorMsg: 'As senhas não conferem, por favor, insira a sua senha novamente',
        },
        //Foto
        {
            messages: [
                'Agora vou precisar que você me envie uma foto sua para ativar o seu login por biometria facial.',
                'Ahh, e para conseguirmos utiliza-la na biometria, é necessário que você não esteja de óculos ou algum acessório, como chapéu ou boné. 😊'
            ],
            fieldName: 'imagem',
            inputType: 'text',
            readonly: true,
            submitIcon: "camera",
            placeholder: "Clique na câmera",
            submitInterceptor: (submit) => this.takePhoto(submit),
            rule: (value) => value != 'false',
            errorMsg: 'Não consegui carregar a imagem, verifique se você concedeu permissão ao app.',
        },
    ];

    private takePhoto(submit: (value: string, skipUserInput: boolean, isBase64: boolean) => void) {
        NativeService.TakePicture(CameraOptions.Front)
            .then(resp => {
                submit(resp, false, true);
            })
            .catch(() => {
                submit("false", true, false);
            });
    }

    private finishRegister() {
        this.$store.commit('SetUserForm', this.fields);
        this.$store.commit('replace', { page: 'termos', transition: 'toLeft' });
    }

    private recoverPassword() {
        this.$store.commit('replace', { page: 'recsenha', transition: 'toLeft' });
    }
}

export default CriarContaChat;
