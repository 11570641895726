
import { Component, Vue } from 'vue-property-decorator';
import VsLoadingModal from '@/components/layouts/vs-LoadingModal.vue';
import { VsOption } from '@/components/layouts/vs-alert';
import AuthCredentials from '../../models/AuthCredentials';
import UserPostDTO from '@/DTOs/UsuarioPostDTO';
import { deviceId } from '@/utils/stringUtils';

@Component({ components: { VsLoadingModal } })
class CriarContaTermos extends Vue {
 
    private termsChecked: boolean = false;
    private finishModalOpened: boolean = false;
    private loading: boolean = false;
    
    private goBack() {
        const options: VsOption[] = [
            {
                title: "Sair",
                action: () => this.$store.commit('goBack')
            },
            {
                title: "Cancelar",
                primary: true
            }
        ];
        this.$pilotarAlert('Deseja realmente sair do cadastro?', 'Ao fechar você perderá os dados informados no chat.', options);
    }

    private submit(): void {
        this.loading = true;
        this.$store.dispatch('SignUp')
            .then(() => {
                const userForm = this.$store.getters['getUserForm'] as UserPostDTO;
                const credentials: AuthCredentials = {
                    emailOuCpf: userForm.email,
                    chave: userForm.senha,
                    deviceId: deviceId()
                };

                let options: VsOption[];

                // Fazer Login automaticamente
                this.$store.dispatch('SignIn', credentials)
                    .then(() => {
                        options  = [{
                            title: "Continuar",
                            action: () => this.$store.commit('clearStack', { page: 'home' })
                        }];

                    })
                    .catch(() => {
                        options  = [{
                            title: "Continuar",
                            action: () => this.$store.commit('clearStack', { page: 'login' })
                        }];
                    })
                    .finally(() => {
                        this.$pilotarAlert('Cadastro concluído!', `Parabéns, ${this.username}! Agora vou poder te proporcionar uma melhor experiência durante o seu processo de formação.`, options);
                    });
            })
            .catch(error => {
                this.loading = false;
                this.$pilotarAlert('Opss...', error, [
                    {
                        title: "Tentar novamente",
                        primary: true
                    }
                ]);
            });
    }

    private get username(): string {
        return this.$store.getters['GetUserName'];
    }
}

export default CriarContaTermos;
