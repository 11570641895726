
import { Component, Vue } from 'vue-property-decorator';
import AnimatedScrollTo from 'animated-scroll-to';

import DetalheAvaliacao from './DetalheAvaliacao.vue';
import DetalheConteudo from './DetalheConteudo.vue';
import DetalheFotos from './DetalheFotos.vue';
import DetalhePercurso from './DetalhePercurso.vue';
import { ClassDetail } from '@/store/modules/integrationStore';
import moment from 'moment';
import ErrorView from '@/views/ErrorView.vue';

@Component({ components: { DetalheAvaliacao, DetalheConteudo, DetalheFotos, DetalhePercurso, ErrorView } })
class DetalheAulaMainPage extends Vue {
   
    // ======== Ciclo de vida ========
    private beforeMount() {
        this.page = this.$route.query.c as string;
        this.loadClassDetail();
    }

    private mounted(){
        if(this.$route.query.c != 'DetalhePercurso') {
            this.opened = true;
        }
    }

    // ========== Variáveis computadas ========
    private get aula(): ClassDetail{
        return this.$store.getters['getClassDetail'];
    }
    private get date(){
        if(this.aula != null) {
            return moment(this.aula.inicio, 'DD-MM-YYYY HH:mm:ss');
        }
        else {
            return moment();
        }
    }
    private get inicio(){
        return this.date.format('DD / MMMM / YYYY');
    }
    private get hora(){
        return this.date.format('HH:mm');
    }


    private transition: string = '';
    private page: string = 'DetalhePercurso';
    private pages: string[] = ['DetalhePercurso', 'DetalheConteudo', 'DetalheFotos', 'DetalheAvaliacao'];
    private error: string = '';
    private opened: boolean = false;
    private loading: boolean = true;

    private loadClassDetail(){
        this.loading = true;
        this.error = '';
        this.$store.dispatch('loadClassDetail', this.$route.params.id)
            .catch(error => this.error = error)
            .finally(() => this.loading = false);
    }

    private navigate(el: HTMLElement, page: string) {
        const container = this.$refs.options as HTMLElement;
        AnimatedScrollTo(el, {
            cancelOnUserAction: true,
            elementToScroll: container,
        });
        if(page != this.page) {
            this.transition = this.pages.indexOf(page) > this.pages.indexOf(this.page) ? 'toLeft' : 'toRight';
            this.$router.replace({ name: 'auladetalhe', params: { id: this.$route.params.id }, query: { c: page } });
            // this.$store.commit('replace', { page: 'auladetalhe', params: { id: this.$route.params.id }, query: { c: page } });
            this.page = page;
        }
    }
}

export default DetalheAulaMainPage;
