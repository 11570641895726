export const MILESECONDS_IN_DAY: number = 86400000;
export const MONTHS = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];
export const WEEKDAYS = ['dom.', 'seg.', 'ter.', 'qua.', 'qui.', 'sex.', 'sáb.'];

export const dateText = (date: Date): string => {
    if (date != null) {
        return `${date.getDate()} de ${MONTHS[date.getMonth()]} de ${date.getFullYear()}`;
    }
    return "";
};
