export function phoneValidator(value: string){
    const phoneMask = /^\([1-9]{2}\) [0-9]{5}-[0-9]{4}/i;
    return phoneMask.test(value);
}

export function passwordValidator(value: string) {
    const hasNumber = /[0-9]/.test(value);
    const hasLetter = /[A-Za-z]/.test(value);
    const hasEightCharacters = value.length >= 8;
    return hasNumber && hasLetter && hasEightCharacters;
}

export function emojiValidator(value: string) {
    const emojiRegex = /^(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;
    return !emojiRegex.test(value);
}

export function cpfValidator(value: string) {
    const noMaskCpf = value.replace(new RegExp('[.-]','gi'), '');
    let Soma;
    let Resto;
    Soma = 0;
    if (noMaskCpf == "00000000000") return false;
    if (noMaskCpf == "11111111111") return false;
    if (noMaskCpf == "22222222222") return false;
    if (noMaskCpf == "33333333333") return false;
    if (noMaskCpf == "44444444444") return false;
    if (noMaskCpf == "55555555555") return false;
    if (noMaskCpf == "66666666666") return false;
    if (noMaskCpf == "77777777777") return false;
    if (noMaskCpf == "88888888888") return false;
    if (noMaskCpf == "99999999999") return false;
     
    for (let i=1; i<=9; i++) Soma = Soma + parseInt(noMaskCpf.substring(i-1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;
   
    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(noMaskCpf.substring(9, 10)) ) return false;
   
    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(noMaskCpf.substring(i-1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;
   
    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(noMaskCpf.substring(10, 11) ) ) return false;
    return true;
}