
import { Component, Vue, Prop } from 'vue-property-decorator';
import ErrorIllustration from '@/components/illustrations/error.vue';
@Component({ components: { ErrorIllustration } })
class ErrorView extends Vue {

    @Prop({ type: String })
    private readonly error?: string;

    @Prop({ type: Boolean, default: true })
    private readonly showIllustration!: boolean;
}

export default ErrorView;
