
import { Component, Vue } from 'vue-property-decorator';

@Component({})
class DetalheConteudo extends Vue {

    private get contaudos(){
        return this.$store.getters['getConteudos'];
    }

    private get faltas(){
        return this.$store.getters['getFaltas'];
    }
}

export default DetalheConteudo;
