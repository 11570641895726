import AddressDTO from "@/modules/core/DTOs/AddressDTO";
import { format, parse } from "date-fns";

export const sentenceCase = (value: string): string => {
    const lowerCase = value.slice(1, value.length)?.toLowerCase();
    const upperCase = value[0]?.toUpperCase();
    if(lowerCase && upperCase) {
        return upperCase + lowerCase;
    }
    return value;
}; 

export const addressText = (address: AddressDTO): string  => {
    return `${address.street}, ${address.buildingNumber}, ${address.district}, ${address.city}/${address.state?.toUpperCase()}, ${address.zipCode}`;
};

export const titleCase = (value: string | undefined): string  => {
    if (!value) return "";
    return value.toLowerCase().split(" ").map(word => {
        return ['de'].includes(word) || ['da'].includes(word) || ['do'].includes(word) ? word : sentenceCase(word);
    }).join(" ");
};

export const parseDate = (date: string) => {
    return parse(date, "dd/MM/yyyy HH:mm", new Date());
};

export const parseQueryDate = (date: string) => {
    return parse(date, 'dd-MM-yyyy', new Date());
};

export const formatDate = (date: Date | string, f?: string) => {
    if (typeof date === 'string')
        date = new Date(date);
    return format(date, f ?? 'dd-MM-yyyy');
};

export const removeCnpjMask = (text: string) => {
    return text?.replace(new RegExp('[./-]', 'gi'), '');
};

export const removeCpfMask = (text: string) => {
    return text?.replace(new RegExp('[.-]', 'gi'), '');
};

export const maskCnpj = (value: string) => {
    const part1 = value.substr(0, 2);
    const part2 = value.substr(2,3);
    const part3 = value.substr(5,3);
    const part4 = value.substr(8,4);
    const part5 = value.substr(12,2);
    return `${part1}.${part2}.${part3}/${part4}-${part5}`;
};

export const convertFileToBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const fileExtension = (fileName: string) => {
    return fileName.substring(fileName.lastIndexOf(".") + 1);
};

export const uuidv4 = () => {
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
        d += performance.now(); //use high-precision timer if available
    }
   
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
};

export const deviceId = (): string => {
    let id: string = localStorage.getItem("deviceId") || "";
    if (id == null || id == "") {
        id = uuidv4();
        localStorage.setItem("deviceId", id);
    }
    return id;
};

export const parseJwt = (token: string): any => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};