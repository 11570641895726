
import { Component, Vue } from 'vue-property-decorator';
import SurveyDTO from '@/modules/rating/DTOs/surveys/SurveyDTO';
import TagDTO from '@/modules/rating/DTOs/surveys/TagDTO';
import AvaliacaoService from '../../services/AvaliacaoService';
import SurveysService from '@/modules/rating/services/SurveysService';
import AuthResponse from '@/models/AuthResponse';

@Component({})
class DetalheAvaliacao extends Vue {

    public loading: boolean = false;
    public avaliarModalOpened: boolean = false;

    // Formulário
    public selectedTags: TagDTO[] = [];
    public rating: number = 0;
    public commentary: string = '';
    public survey!: SurveyDTO;

    public get avaliacao(): any | null {
        return this.$store.getters['getAvaliacaoAulaPratica'];
    }

    public get wrapperHeight(): string {
        if((this.rating == 0 || this.rating == 5) || this.avaliacao != null) {
            return '10rem';
        }
        else {
            return '22rem';
        }
    }

    public get user(): AuthResponse {
        return this.$store.getters['getUser'];
    }

    public submitAvaliacao() {
        if(this.rating === 0) {
            this.$pilotarAlert('Opss...', "Para enviar uma avaliação é preciso selecionar uma nota");
        }
        else {
            this.loading = true;

            if (this.rating === 5) {
                this.selectedTags = [];
                this.commentary = '';
            }

            const data = {
                codAulaPratica: this.$route.params.id, 
                comentario: this.commentary, 
                tags: this.survey.tags.filter(el => this.selectedTags.includes(el)).map(el => el.name),
                nota: this.rating,
                cnpjCfc: this.user.cnpjCfc,
                nomeCfc: this.user.nomeCfc,
                estado: this.user.estado?.toLowerCase()
            };
            this.$store.dispatch('PostAvaliacaoAulaPratica', data)
                .catch(error => {
                    this.$pilotarAlert('Opss...', error);
                })
                .finally(() => this.loading = false);
        }
    }

    public async loadSurvey(){
        this.loading = true;
        try {
            this.survey = await SurveysService.getById(process.env.VUE_APP_SURVEY_ID);
            this.avaliarModalOpened = true;
        }
        catch(error){
            this.$pilotarAlert('Opss...', error as any);
        }
        finally{
            this.loading = false;
        }
    }

}

export default DetalheAvaliacao;
