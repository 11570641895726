
import { Component, Vue } from 'vue-property-decorator';
import PImageLoader from '@/components/p-ImageLoader.vue';
import { ClassDetail } from '@/store/modules/integrationStore';

@Component({ components: { PImageLoader } })
class DetalheFotos extends Vue {

    private get images() {
        return (this.$store.getters['getClassDetail'] as ClassDetail).idImages;
    }

    private opened: boolean = false;
    private selectedIndex: number = 0;

    private imagesDetail: string[] = Array(this.images.length).fill('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAQSURBVHgBAQUA+v8AAAAAAAAFAAFkeJU4AAAAAElFTkSuQmCC');

    private checkDetail(index: number) {
        this.opened = true;
        this.selectedIndex = index;
        // if(this.imagesDetail.find(el => el.length == 0) == null) {
        // }
    }

    private get loaded(){
        return true;
    }
}

export default DetalheFotos;
